<template>
  <div v-if="allowToSwitch && (location || locations)" class="px-3">
    <v-select :value="location ? location.id : 0" :items="elements" :label="$t('location.title')" item-value="id" item-text="name"
              :disabled="elements.length <= 1 || loading" :loading="loading" autocomplete="off" dense outlined hide-details
              @change="switchLocation"
    />
  </div>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import ability from '@/ability';
  import UserRole from '@/services/order/UserRole';

  export default {
    name: 'SwitchLocation',
    props: {
      location: {
        type: Object,
        default: undefined
      },
      locations: {
        type: Array,
        default: undefined
      }
    },
    data: () => ({
      elements: [],
      loading: false,
      allowToSwitch: true
    }),
    watch: {
      locations: function () {
        this.initElements();
      }
    },
    mounted () {
      this.initElements();
      this.allowToSwitch = ![UserRole.admin_accountant, UserRole.canvasser, UserRole.commercial, UserRole.buyer].includes(this.$store.state.user?.role);
    },
    methods: {
      initElements () {
        this.elements = [];
        if (this.locations !== undefined && this.locations.length > 0) {
          if (this.locations.length > 1 && (this.$can('read', 'CustomerService') || this.$can('read', 'Overview'))) {
            this.elements.push({ id: 0, name: this.$i18n.t('location.all') });
          }
          this.locations.forEach((location) => {
            this.elements.push({ id: location.id, name: location.city });
          });
        }
      },
      async switchLocation (locationId) {
        if (this.loading) {
          return false;
        }

        this.loading = true;
        try {
          const response = await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'user/switch/location', { locationId: locationId });
          if (response?.data?.token !== undefined && response?.data?.user !== undefined) {
            const token = response.data.token;
            const user = response.data.user;
            this.$ability.update(ability(user).rules);
            this.$store.commit('SET_TOKEN', token);
            this.$store.commit('SET_USER', user);
            this.$store.commit('SET_LOCATION', {
              location: user.location,
              locations: user.locations
            });
          }
          // await new Promise((resolve) => setTimeout(resolve, 100)); // TODO remove?
          await this.$router.push({ name: 'home' });
          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('login.error'));
        }
        this.loading = false;
      }
    }
  };
</script>
