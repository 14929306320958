<template>
  <v-navigation-drawer id="core-navigation-drawer" v-model="drawer" :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'" :expand-on-hover="expandOnHover"
                       :right="$vuetify.rtl" :src="barImage" mobile-breakpoint="960" app width="260" v-bind="$attrs"
  >
    <template #img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>
    <v-divider class="mb-1" />
    <v-list dense nav>
      <v-list-item>
        <v-list-item-avatar class="align-self-center" color="transparent" contain rounded="0">
          <v-img src="/logo.svg" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="display-1" v-text="profile.title" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider class="mb-2" />
    <div v-if="$store.state.user" class="px-3">
      <switch-location :location="location" :locations="locations" />
    </div>
    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>
        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import { mapState } from 'vuex';
  import SwitchLocation from '@/views/components/business/auth/SwitchLocation';

  export default {
    name: 'CoreDrawer',
    components: { SwitchLocation },
    props: {
      expandOnHover: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      items: [],
      location: undefined,
      locations: [],
      unsubscribe: undefined
    }),
    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer;
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val);
        }
      },
      computedItems () {
        return this.items.map(this.mapItem);
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('app.name')
        };
      }
    },
    mounted () {
      this.updateDrawer();
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        switch (mutation.type) {
          case 'SET_TOKEN':
          case 'SET_USER':
          case 'SET_LOCATION':
            this.updateDrawer();
            break;
        }
      });
      this.$ability.on('updated', () => {
        this.updateDrawer();
      });
    },
    destroyed: function () {
      this.updateDrawer();
      if (typeof this.unsubscribe === 'function') {
        this.unsubscribe();
      }
    },
    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title)
        };
      },
      updateDrawer () {
        this.location = this.$store.state.location;
        this.locations = this.$store.state.locations;

        this.items = [];
        if (this.$store.state.token === undefined) {
          this.items.push({
            icon: 'mdi-account',
            title: 'login.title',
            subtitle: '',
            to: '/'
          });
        } else if (this.location === undefined && Array.isArray(this.locations) && this.locations.length > 1) {
          if (this.$can('read', 'Overview')) {
            this.items.push({
              icon: 'mdi-bulletin-board',
              title: 'overview.title',
              to: '/overview'
            });
          }

          if (this.$can('read', 'CustomerService')) {
            const customerServiceSubMenu = [{
              icon: 'mdi-television-guide',
              title: 'customerService.guide.title',
              to: '/customer-service/guide'
            }, {
              icon: 'mdi-monitor-shimmer',
              title: 'customerService.orders.title',
              to: '/customer-service/orders'
            }, {
              icon: 'mdi-credit-card-multiple',
              title: 'customerService.payments.title',
              to: '/customer-service/payments'
            }, {
              icon: 'mdi-account-multiple',
              title: 'customerService.users.title',
              to: '/customer-service/users'
            }];

            if (this.$can('read', 'CustomerService.Prospect')) {
              customerServiceSubMenu.push({
                icon: 'mdi-target',
                title: 'customerService.prospects.title',
                to: '/customer-service/prospects'
              });
            }

            if (this.$can('read', 'CustomerService.New')) {
              customerServiceSubMenu.push({
                icon: 'mdi-new-box',
                title: 'customerService.newCustomers.title',
                to: '/customer-service/new-customers'
              });
              // customerServiceSubMenu.push({
              //   icon: 'mdi-fruit-pear',
              //   title: 'customerService.newMarketCustomers.title',
              //   to: '/customer-service/new-market-customers'
              // });
            }

            if (this.$can('read', 'CustomerService.Inactive')) {
              customerServiceSubMenu.push({
                icon: 'mdi-bell-sleep-outline',
                title: 'customerService.inactiveCustomers.title',
                to: '/customer-service/inactive-customers/v2'
              });
            }

            if (this.$can('read', 'Order.Editor')) {
              customerServiceSubMenu.push({
                icon: 'mdi-shopping',
                title: 'orderEditor.title.new',
                to: '/order/editor'
              });
            }

            this.items.push({
              icon: 'mdi-file-phone',
              title: 'customerService.title',
              children: customerServiceSubMenu
            });
          }

          if (this.$can('read', 'Search')) {
            this.items.push({
              icon: 'mdi-magnify',
              title: 'search.title',
              to: '/search'
            });
          }

          const subProspectingMetaMenu = [];
          if (this.$can('read', 'Admin.Meta.Distribution')) {
            subProspectingMetaMenu.push({
              icon: 'mdi-newspaper-variant-multiple',
              title: 'admin.sub.distribution.title',
              to: '/admin/distribution/zones'
            });
          }
          if (this.$can('read', 'Prospecting')) {
            subProspectingMetaMenu.push({
              icon: 'mdi-map-marker',
              title: 'prospecting.zones.title',
              to: '/prospecting/zones'
            });
            subProspectingMetaMenu.push({
              icon: 'mdi-label-percent',
              title: 'prospecting.discounts.title',
              to: '/prospecting/discounts'
            });
            subProspectingMetaMenu.push({
              icon: 'mdi-finance',
              title: 'prospecting.results.title',
              to: '/prospecting/results'
            });
          }
          if (subProspectingMetaMenu.length > 0) {
            this.items.push({
              icon: 'mdi-door',
              title: 'prospecting.title',
              children: subProspectingMetaMenu
            });
          }

          if (this.$can('read', 'Purchase')) {
            this.items = this.items.concat([{
              icon: 'mdi-plus-box',
              title: 'purchase.title',
              children: [{
                icon: 'mdi-database-plus-outline',
                title: 'purchase.merchandise.title',
                to: '/purchase/merchandises'
              }, {
                icon: 'mdi-finance',
                title: 'purchase.analytic.title',
                to: '/purchase/analytics'
              }]
            }]);
          }

          if (this.$can('read', 'Report.Meta')) {
            this.items = this.items.concat([{
              icon: 'mdi-chart-bar',
              title: 'metaReport.title',
              children: [{
                icon: 'mdi-white-balance-sunny',
                title: 'metaReport.metaActivity.title',
                to: '/meta/activity'
              }, {
                icon: 'mdi-human-male-female-child',
                title: 'metaReport.metaCrm.title',
                to: '/meta/crm'
              }, {
                icon: 'mdi-heart',
                title: 'metaReport.metaQuality.title',
                to: '/meta/quality'
              }, {
                icon: 'mdi-chart-areaspline',
                title: 'metaReport.metaPerformance.title',
                to: '/meta/performance'
              }, {
                icon: 'mdi-currency-eur',
                title: 'metaReport.metaAccounting.title',
                to: '/meta/accounting'
              }]
            }]);
          }

          const subAdminMetaMenu = [];
          if (this.$can('read', 'Admin.Meta.Product')) {
            subAdminMetaMenu.push({
              icon: 'mdi-folder-multiple',
              title: 'admin.sub.productTypes.title',
              to: '/product/types'
            });
            subAdminMetaMenu.push({
              icon: 'mdi-folder-multiple',
              title: 'admin.sub.productCategories.title',
              to: '/product/categories'
            });
            subAdminMetaMenu.push({
              icon: 'mdi-food',
              title: 'admin.sub.products.title',
              to: '/products'
            });
            subAdminMetaMenu.push({
              icon: 'mdi-folder-multiple',
              title: 'admin.sub.productGroups.title',
              to: '/product/groups'
            });
            subAdminMetaMenu.push({
              icon: 'mdi-food',
              title: 'admin.sub.productOptions.title',
              to: '/product/options'
            });
          }
          if (this.$can('read', 'Admin.Meta.Discount')) {
            subAdminMetaMenu.push({
              icon: 'mdi-label-percent',
              title: 'admin.sub.printDiscounts.title',
              to: '/admin/print/discounts'
            });
          }

          if (subAdminMetaMenu.length > 0) {
            this.items.push({
              icon: 'mdi-database',
              title: 'admin.title',
              children: subAdminMetaMenu
            });
          }

          this.items.push({
            icon: 'mdi-logout',
            title: 'logout.title',
            to: '/logout'
          });
        } else {
          if (this.$can('read', 'Dashboard')) {
            this.items.push({
              icon: 'mdi-view-dashboard',
              title: 'dashboard.title',
              to: '/dashboard'
            });
          }

          if (this.$can('read', 'Order.Preparation')) {
            this.items.push({
              icon: 'mdi-food',
              title: 'preparation.title',
              to: '/preparation'
            });
          }

          if (this.$can('read', 'Order.Delivery.All') && this.$can('read', 'Order.Delivery.My')) {
            this.items = this.items.concat([{
              icon: 'mdi-truck-delivery',
              title: 'delivery.title',
              children: [{
                icon: 'mdi-calendar-check-outline',
                title: 'driverDashboard.title',
                to: '/driverdashboard'
              }, {
                icon: 'mdi-truck-delivery-outline',
                title: 'delivery.sub.all.title',
                to: '/delivery'
              }, {
                icon: 'mdi-moped-outline',
                title: 'delivery.sub.my.title',
                to: '/mydelivery'
              }]
            }]);
          } else if (this.$can('read', 'Order.Delivery.All')) {
            this.items.push({
              icon: 'mdi-truck-delivery-outline',
              title: 'delivery.sub.all.title',
              to: '/delivery'
            });
          } else if (this.$can('read', 'Order.Delivery.My')) {
            this.items.push({
              icon: 'mdi-calendar-check-outline',
              title: 'driverDashboard.title',
              to: '/driverdashboard'
            });
            this.items.push({
              icon: 'mdi-moped-outline',
              title: 'delivery.sub.my.title',
              to: '/mydelivery'
            });
          }

          if (['orgeval', 'poissy'].includes(this.location?.key) && this.$can('read', 'Order.Pickup')) {
            this.items.push({
              icon: 'mdi-storefront',
              title: 'pickup.title',
              to: '/pickup'
            });
          }

          if (this.$can('read', 'Order.All')) {
            this.items.push({
              icon: 'mdi-shopping',
              title: 'order.title',
              to: '/orders'
            });
          }

          if (this.$can('read', 'Search')) {
            this.items.push({
              icon: 'mdi-magnify',
              title: 'search.title',
              to: '/search'
            });
          }

          if (this.$can('read', 'Marketing')) {
            this.items.push({
              icon: 'mdi-bullseye-arrow',
              title: 'marketing.title',
              children: [{
                icon: 'mdi-email-multiple-outline',
                title: 'marketing.campaign.list.title',
                to: '/marketing/campaigns'
              }]
            });
          }

          if (this.$can('read', 'Distribution')) {
            this.items.push({
              icon: 'mdi-newspaper-variant-multiple',
              title: 'distribution.title',
              to: '/distribution'
            });
          }

          if (this.$can('read', 'Report.Activity') || this.$can('read', 'Report.Accounting')) {
            const children = [];
            if (this.$can('read', 'Report.Activity')) {
              children.push({
                icon: 'mdi-white-balance-sunny',
                title: 'report.activity.title',
                to: '/reports'
              });
            }
            if (this.$can('read', 'Report.Accounting')) {
              children.push({
                icon: 'mdi-currency-eur',
                title: 'report.accounting.title',
                to: '/accounting'
              });
            }

            this.items.push({
              icon: 'mdi-chart-bar',
              title: 'report.title',
              children: children
            });
          }

          // if (this.$can('read', 'UserSetting')) {
          //   this.items.push({
          //     icon: 'mdi-cogs',
          //     title: 'userSettings.title',
          //     to: '/usersettings'
          //   });
          // }

          const subAdminLocationMenu = [];
          if (this.$can('read', 'Admin.Location.BusinessHours')) {
            subAdminLocationMenu.push({
              icon: 'mdi-calendar',
              title: 'admin.sub.businessHours.title',
              to: '/businessHours'
            });
          }
          // if (this.$can('read', 'Admin.Location.UsersSchedule')) {
          //   subAdminLocationMenu.push({
          //     icon: 'mdi-calendar-range',
          //     title: 'admin.sub.usersSchedule.title',
          //     to: '/users/schedule'
          //   });
          // }
          if (this.$can('read', 'Admin.Location.Task')) {
            subAdminLocationMenu.push({
              icon: 'mdi-calendar-check',
              title: 'admin.sub.tasks.title',
              to: '/tasks/list'
            });
          }
          if (this.$can('read', 'Admin.Location.User')) {
            subAdminLocationMenu.push({
              icon: 'mdi-account-multiple',
              title: 'admin.sub.users.title',
              to: '/users/list'
            });
          }
          if (this.$can('read', 'Admin.Location.DeliveryZones')) {
            subAdminLocationMenu.push({
              icon: 'mdi-map-marker',
              title: 'admin.sub.deliveryZones.title',
              to: '/delivery/zones'
            });
          }

          if (subAdminLocationMenu.length > 0) {
            this.items.push({
              icon: 'mdi-database',
              title: 'admin.title',
              children: subAdminLocationMenu
            });
          }

          this.items.push({
            icon: 'mdi-logout',
            title: 'logout.title',
            to: '/logout'
          });
        }
      }
    }
  };
</script>
